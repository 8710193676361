import {MDCSnackbar} from '@material/snackbar';

export function snackbar () {
  window.addEventListener('load', function() {
    const snackbar = new MDCSnackbar(document.querySelector('.mdc-snackbar'));

    if (document.querySelector('.mdc-snackbar__label').textContent.trim() > "") {
      snackbar.open();
    }
  });
}

