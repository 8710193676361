import {MDCDrawer} from "@material/drawer";
import {MDCTopAppBar} from "@material/top-app-bar";
import {MDCMenu} from '@material/menu';

export function appBarNav () {
  window.addEventListener('load', function() {
    const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));
    const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'));
    const menu = new MDCMenu(document.querySelector('.mdc-menu'));

    topAppBar.setScrollTarget(document.getElementById('main-content'));

    topAppBar.listen('MDCTopAppBar:nav', () => {
      drawer.open = !drawer.open;
    });

    document.getElementById('profile-button').addEventListener("click", function(){
      menu.open = true;
    });
  });
}
