import {MDCRipple} from '@material/ripple';
import {MDCLineRipple} from '@material/line-ripple';

export function ripples () {
  window.addEventListener('load', function() {
    const rippleSelectors = '.mdc-button, .mdc-fab, .mdc-icon-button, .mdc-card__primary-action';
    const lineRippleSelectors = '.mdc-line-ripple'

    const ripples = [].map.call(document.querySelectorAll(rippleSelectors), function(el) {
      return new MDCRipple(el);
    });

    const lineRipples = [].map.call(document.querySelectorAll(lineRippleSelectors), function(el) {
      return new MDCLineRipple(el);
    });

  });
}

