import {MDCTextField} from '@material/textfield';
import {MDCTextFieldHelperText} from '@material/textfield/helper-text';

import {MDCSwitch} from '@material/switch';

import {MDCSelect} from '@material/select';
import {MDCSelectHelperText} from '@material/select/helper-text';

import {MDCCheckbox} from '@material/checkbox';

export function formFields () {
  window.addEventListener('load', function() {
    const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
      return new MDCTextField(el);
    });

    const textFieldHelperTexts = [].map.call(document.querySelectorAll('.mdc-text-field-helper-text'), function(el) {
      return new MDCTextFieldHelperText(el);
    });

    const selects = [].map.call(document.querySelectorAll('.mdc-select'), function(el) {
      return new MDCSelect(el);
    });

    const selectHelperTexts = [].map.call(document.querySelectorAll('.mdc-select-helper-text'), function(el) {
      return new MDCSelectHelperText(el);
    });

    const switchControls = [].map.call(document.querySelectorAll('.mdc-switch'), function(el) {
      return new MDCSwitch(el);
    });

    const checkboxes = [].map.call(document.querySelectorAll('.mdc-checkbox'), function(el) {
      return new MDCCheckbox(el);
    });


  });
}


