import {MDCDataTable} from '@material/data-table';

export function dataTable () {
  window.addEventListener('load', function() {
    const dataTables = [].map.call(document.querySelectorAll('.mdc-data-table'), function(el) {
      return new MDCDataTable(el);
    });

    if (document.querySelector('.available_stimuli')) {
      var tocAddObj
      var tocAddElement = document.querySelector('.available_stimuli')

      var targetAddInput = document.querySelector('#add_selected_ids');

      dataTables.forEach ( function(obj) {
        if (obj.root_.classList.contains('available_stimuli')) {
          tocAddObj = obj;
        }
      });

      tocAddElement.addEventListener('MDCDataTable:rowSelectionChanged', function(e) {
        targetAddInput.value = tocAddObj.getSelectedRowIds();
      });

      tocAddElement.addEventListener('MDCDataTable:selectedAll', function(e) {
        targetAddInput.value = tocAddObj.getSelectedRowIds();
      });

      tocAddElement.addEventListener('MDCDataTable:unselectedAll', function(e) {
        targetAddInput.value = tocAddObj.getSelectedRowIds();
      });
    }

    if (document.querySelector('.current_toc')) {
      var tocRemoveObj
      var tocRemoveElement = document.querySelector('.current_toc')

      var targetRemoveInput = document.querySelector('#remove_selected_ids');

      dataTables.forEach ( function(obj) {
        if (obj.root_.classList.contains('current_toc')) {
          tocRemoveObj = obj;
        }
      });

      tocRemoveElement.addEventListener('MDCDataTable:rowSelectionChanged', function(e) {
        targetRemoveInput.value = tocRemoveObj.getSelectedRowIds();
      });

      tocRemoveElement.addEventListener('MDCDataTable:selectedAll', function(e) {
        targetRemoveInput.value = tocRemoveObj.getSelectedRowIds();
      });

      tocRemoveElement.addEventListener('MDCDataTable:unselectedAll', function(e) {
        targetRemoveInput.value = tocRemoveObj.getSelectedRowIds();
      });
    }

    if (document.querySelector('.available_gdm')) {
      var tocAddObj
      var tocAddElement = document.querySelector('.available_gdm')

      var targetAddInput = document.querySelector('#add_selected_ids');

      dataTables.forEach ( function(obj) {
        if (obj.root_.classList.contains('available_gdm')) {
          tocAddObj = obj;
        }
      });

      tocAddElement.addEventListener('MDCDataTable:rowSelectionChanged', function(e) {
        targetAddInput.value = tocAddObj.getSelectedRowIds();
      });

      tocAddElement.addEventListener('MDCDataTable:selectedAll', function(e) {
        targetAddInput.value = tocAddObj.getSelectedRowIds();
      });

      tocAddElement.addEventListener('MDCDataTable:unselectedAll', function(e) {
        targetAddInput.value = tocAddObj.getSelectedRowIds();
      });
    }

  });
}


